import React, { useState } from "react"
import Logo from "./logo"
import { Link } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"

const Header = props => {
  const { isLoggedIn } = useAuth()
  const [open, setopen] = useState(false)
  return (
    <header
      style={{ padding: `${props.padding}` }}
      className="z-50 nav lg:px-16 px-6 flex flex-wrap items-center lg:py-0 py-2 bg-transparent sticky top-0 left-0 right-0 transition-all duration-200 ease-linear"
    >
      <div className="flex-1 flex justify-between items-center">
        <Link to="/">
          <Logo
            color={props.logoColor}
            style={{
              width: "10%",
              minWidth: "150px",
              float: "left",
              marginTop: "0.5rem",
            }}
          />
        </Link>
      </div>

      <button
        onClick={() => setopen(!open)}
        className="pointer-cursor lg:hidden block"
      >
        <svg
          className="fill-current text-gray-900"
          style={{ color: `${props.textColor}` }}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>

      <nav
        className={`lg:flex lg:items-center lg:w-auto w-full transition duration-500 ease-in-out ${
          open ? "block" : "hidden"
        } `}
        id="menu"
      >
        <div className="lg:flex items-center justify-between text-base pt-4 lg:pt-0">
          <Link
            to="/about"
            className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-gray-800 no-underline text-gray-800"
            style={{ color: `${props.textColor}`, fontWeight: 500 }}
          >
            <b>About</b>
          </Link>

          <Link
            to="/communities"
            className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-gray-800 no-underline text-gray-800"
            style={{ color: `${props.textColor}`, fontWeight: 500 }}
          >
            <b>Communities</b>
          </Link>

          <Link
            to="/contact"
            className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-gray-800 no-underline text-gray-800"
            style={{ color: `${props.textColor}`, fontWeight: 500 }}
          >
            <b>Contact</b>
          </Link>
          {isLoggedIn ? (
            <Link
              to="/dashboard"
              className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-gray-800 lg:mb-0 mb-2 no-underline text-gray-800"
              style={{ color: `${props.textColor}`, fontWeight: 500 }}
            >
              <b>Dashboard</b>
            </Link>
          ) : (
            <Link
              to="/login"
              className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-gray-800 lg:mb-0 mb-2 no-underline text-gray-800"
              style={{ color: `${props.textColor}`, fontWeight: 500 }}
            >
              <b>Login</b>
            </Link>
          )}
        </div>
      </nav>
    </header>
  )
}

export default Header
