import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import "./layout.css"
import Reveal from "react-reveal/Reveal"
import Footer from "../components/footer"

const DefaultLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <Reveal>
          <main
            style={{
              margin: `50px auto`,
              maxWidth: 1366,
              padding: `0 1.0875rem 1.45rem`,
              minWidth: "70%",
              flex: 1,
            }}
          >
            {/* <div>
              {values.dark}{" "}
              <button onClick={values.toggleDark}>
                {values.dark ? (
                  <span>Light mode ☀</span>
                ) : (
                  <span>Dark mode ☾</span>
                )}
              </button>
            </div> */}
            {/* {console.log(values)} */}
            {children}
          </main>
        </Reveal>
      </div>
      <Footer />
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
