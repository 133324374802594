import React from "react"
import { Link } from "gatsby"
import LogoWhite from "../components/logoWhite"
import styled from "styled-components"
import Twitter from "../images/Twitter-new.svg"
import Instagram from "../images/Instagram-new.svg"

const MainContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: 678px) {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    column-gap: 1rem;
    justify-content: center;
  }
`

const SocialsContainer = styled.div`
  justify-content: center;
  @media (min-width: 678px) {
    justify-content: flex-start;
  }
`

const Footer = props => (
  <footer style={props.style} className="footer">
    <MainContainer>
      <div
        className="footer-item"
        style={{ display: "flex", alignItems: "center" }}
      >
        <LogoWhite
          style={{
            width: "10%",
            minWidth: "150px",
            float: "left",
            marginTop: "0.5rem",
          }}
        />
      </div>
      <div
        className="footer-item footer-links"
        style={{ justifyContent: "center", alignContent: "center" }}
      >
        <p className="footer-link">
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            Home
          </Link>
        </p>
        <p className="footer-link">
          <Link
            to="/dashboard"
            style={{ textDecoration: "none", color: "white" }}
          >
            Dashboard
          </Link>
        </p>
        <p className="footer-link">
          <Link to="/guides" style={{ textDecoration: "none", color: "white" }}>
            Guides
          </Link>
        </p>
        <p className="footer-link">
          <Link to="/about" style={{ textDecoration: "none", color: "white" }}>
            About us
          </Link>
        </p>
        <p className="footer-link">
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            Contact Us
          </Link>
        </p>
        <p className="footer-link">
          <Link to="/faq" style={{ textDecoration: "none", color: "white" }}>
            FAQ
          </Link>
        </p>
      </div>
      <SocialsContainer
        className="footer-item"
        style={{
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
        }}
      >
        <SocialsContainer
          style={{
            display: "flex",
            alignItems: "center",
            flexBasis: "100%",
            marginBottom: "0.8rem",
          }}
        >
          <a
            href="https://twitter.com/YouChangeEarth"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              style={{ display: "inline-block" }}
              src={Twitter}
              alt="twitter"
              className="socials-icon"
            />
            <p
              style={{
                display: "inline-block",
                color: "white",
                textDecoration: "none",
                fontSize: "0.75rem",
                marginLeft: "0.3rem",
              }}
            >
              Twitter
            </p>
          </a>
        </SocialsContainer>
        <SocialsContainer
          style={{
            display: "flex",
            alignItems: "center",
            flexBasis: "100%",
          }}
        >
          <a
            href="https://www.instagram.com/youchangeearthorg/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              style={{ display: "inline-block" }}
              src={Instagram}
              alt="instagram"
              className="socials-icon"
            />
            <p
              style={{
                display: "inline-block",
                color: "white",
                textDecoration: "none",
                fontSize: "0.75rem",
                marginLeft: "0.3rem",
              }}
            >
              Instagram
            </p>
          </a>
        </SocialsContainer>

        <div
          style={{ margin: "auto 0", paddingTop: "2em", textAlign: "center" }}
        >
          <p>© {new Date().getFullYear()}, Built with ❤️ for 🌎</p>
        </div>
      </SocialsContainer>
    </MainContainer>
  </footer>
)

export default Footer
