import React from "react"
import SEO from "../components/seo"
import DefaultLayout from "../layouts/defaultLayout"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  componentDidMount() {
    window.Intercom("show")
  }

  render() {
    return (
      <DefaultLayout>
        <SEO title="Contact Us" />
        <section className="section">
          <div className="container">
            <div className="content" style={{ lineHeight: 2 }}>
              <h1 className="text-5xl font-bold text-center tracking-wide">
                Contact Us
              </h1>
              <br></br>
              <h2 className="text-center text-md ">
                Use the Intercom chat button on the bottom right or email us at
                the following addresses:{" "}
              </h2>
              <br></br>
              <p className="text-center">
                For Partnerships -{" "}
                <a
                  href="mailto:partnerships@youchangeearth.org"
                  style={{
                    textDecoration: "none",
                    color: "var(--green)",
                  }}
                >
                  partnerships@youchangeearth.org
                </a>
              </p>
              <p className="text-center">
                For Press -{" "}
                <a
                  href="mailto:press@youchangeearth.org"
                  style={{
                    textDecoration: "none",
                    color: "var(--green)",
                  }}
                >
                  press@youchangeearth.org
                </a>
              </p>
              <p className="text-center">
                For Enquiries -{" "}
                <a
                  href="mailto:enquiries@youchangeearth.org"
                  style={{
                    textDecoration: "none",
                    color: "var(--green)",
                  }}
                >
                  enquiries@youchangeearth.org
                </a>
              </p>
              <p className="text-center">
                For Support or any other questions -{" "}
                <a
                  href="mailto:team@youchangeearth.org"
                  style={{
                    textDecoration: "none",
                    color: "var(--green)",
                  }}
                >
                  team@youchangeearth.org
                </a>
              </p>
            </div>
          </div>
        </section>
      </DefaultLayout>
    )
  }
}
